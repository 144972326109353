.header-logo {
  width: 60px;
}

.img-fluid-process {
  width: 25%;
  height: auto;
}

.cp {
  cursor: pointer !important;
}

p.error-txt {
  margin-top: 0px;
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
}

p.error-txt.student {
  margin-bottom: 1rem;
}

/* Text Editor style */
.wrapper-class {
  padding: 1rem;
  border: 1px solid #4d4e8d;
}

.editor-class {
  /* background-color:lightgray; */
  padding: 1rem;
  /* border: 1px solid #ccc; */
}

.toolbar-class {
  border: 1px solid #ccc;
}

.autocomplete-dropdown-container {
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 62px;
  left: 0;
  z-index: 9999;
  background: #ffffff;
}

.rdw-dropdown-selectedtext,
.rdw-dropdown-selectedtext:hover {
  text-decoration: none;
  color: #000000;
}

.et-show {
  display: block;
}

.et-hide {
  display: none;
}

.et-link {
  cursor: pointer;
  text-decoration: underline;
  color: #4d4e8d;
}

/* status */
.et_status_fees_payment {
  background-color: #f4edff;
  color: #9d7bce;
}

.et_status_review,
.et_status_review_missing_info {
  background-color: #e7fbf1;
  color: #8ab6a1;
}

.et_status_submitted_to_university {
  background-color: #e3f7ff;
  color: #49bbec;
}

.et_status_conditional_offer,
.et_status_conditional_offer_letter,
.et_status_conditional_offer_missing_info,
.et_status_conditional_offer_interview,
.et_status_unconditional_offer {
  background-color: #fef2db;
  color: #fdb426;
}

.et_status_payment_pending,
.et_status_course_payment,
.et_status_course_payment_docs,
.et_status_course_payment_interview {
  background-color: #ffeaf9;
  color: #d876bd;
}

.et_status_accepted {
  background-color: #effad9;
  color: #96bd45;
}

.et_status_visa {
  background-color: #ffefe3;
  color: #f9ad74;
}

.et_status_enrolled {
  background-color: #f0f0ff;
  color: #4d4e8d;
}

.et_status_rejected {
  background-color: #ffe7e5;
  color: #fd746b;
}

.et_status_cancelled {
  background-color: #f1f1f1;
  color: #000000;
}

/* status end */
.et-logo {
  width: 25%;
  height: auto;
}