.et-data-table {
  margin-bottom: 0;
}

.et-data-table th {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background: #f9fafb;
  color: #b7c1cb;
  text-align: center;
  padding: 15px 20px;
}

.et-data-table td {
  font-size: 13px;
  color: #000000;
  background: #ffffff;
  padding: 15px;
  text-align: center;
  vertical-align: middle;
}

.et-data-table > :not(:first-child) {
  border-top: none;
}

.sorting {
  position: relative;
  cursor: pointer;
  padding-right: 40px !important;
  text-align: left !important;
}

.sorting:before,
.sorting:after {
  position: absolute;
  display: block;
  right: 10px;
  line-height: 10px;
  font-size: 1.3em;
  color: #c3c3c3;
}

.sorting:before {
  content: "▴";
  bottom: 50%;
}

.sorting:after {
  content: "▴";
  transform: rotate(180deg);
  top: 50%;
}

.sorting.sort-asc:before,
.sorting.sort-desc:after {
  color: #000000;
}

.btn-action {
  border: none;
  outline: none;
  background: none;
}

.et-status {
  font-size: 11px;
  font-weight: 400;
  border-radius: 5px;
  padding: 0px 20px;
  display: block;
  margin-bottom: 0;
}

/* Custom Tooltip style start here */
.ed-custom-tooltip {
  width: 100px;
  min-width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 100%;
  left: 15px;
  z-index: 1;
  display: none;
}

.ed-custom-tooltip:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #8ab6a1;
  position: absolute;
  top: -10px;
  left: 10px;
}

.ed-custom-tooltip.open {
  display: block;
}

.filter-tooltip {
  width: 285px;
}

.lfilter-footer {
  border-top: 1px #eeeeee solid;
  padding: 10px;
}

.btn-submit {
  background: #8ab6a1;
  color: #ffffff;
}

.btn-cancel {
  background: #b7c1cb;
}

.page-count-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-count-list a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000000;
}

.page-count-list a:hover {
  background: #8ab6a1;
  color: #ffffff;
}

.table-footer-wrap {
  background: #f9fafb;
  padding: 20px 30px;
  border: 1px #dee2e6 solid;
}

.entry-count {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #b7c1cb;
}

/* Pagination style start here */
.pagination {
  margin-bottom: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li.active a {
  border: 1px solid #4d4e8d !important;
}

.pagination li a {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: none;
  color: #4d4e8d;
  padding: 7px 15px;
  border: 1px #d5d6e7 solid;
  border-radius: 5px;
}

/* Loader Style start here */
.et-loader-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
}

.et-status-wrap img {
  width: 50%;
  height: auto;
}

.et-status-txt {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}

.loader-img {
  width: 100px !important;
  height: 100px !important;
}

.btn-table {
  font-size: 14px;
  padding: 10px 30px;
  background: #4d4e8d;
}

.continue-icn {
  background: url(../img/continue-icn.svg) no-repeat 95% center #4d4e8d;
  padding-right: 60px;
}

.et-data-table .progress {
  height: 0.7rem;
}

.page-per-view {
  width: 150px;
  padding: 3px 20px;
}

.et-table-actions {
  left: -55px;
  top: 150%;
}

.et-table-actions:before {
  left: 80%;
}

.et-status-active {
  color: #0f5132;
  background-color: #8fd0b2;
  border-color: #badbcc;
}

.et-status-inactive {
  color: #000000;
  background-color: #ffaea5;
  border-color: #e4bbbb;
}

.et-img {
  width: 100%;
  height: auto;
}
