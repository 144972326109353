.offcanvas-backdrop {
  display: none;
}
.offcanvas-lg {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0 0 10px #cccccc;
  background: #FFFFFF !important;
}
.offcanvas-header {
  display: flex;
}
.filter-col {
    max-height: 100vh;
    height: 100vh;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #ffffff;
  }
  .filter-card-wrap {
    cursor: auto;
    padding: 10px 20px;
  }
  .filter-card {
    border: 1px solid #eeeeee;
    padding: 10px;
  }
  .filter-card-title {
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #000000;
  }
  ​
  filter-cards .form-select {
    border: solid 1px #f1f1f1;
  }
  .filter-card-wrap .select__indicators {
    display: none;
  }
  .filter-card-wrap .css-1s2u09g-control {
    border-radius: 0;
    border: 1px solid #eeeeee;
  }
  .filter-card-wrap .css-6j8wv5-Input,
  .filter-card-wrap .css-6j8wv5-Input input {
    height: 48px !important;
  }
  .filter-card-wrap .css-14el2xx-placeholder {
    font-size: 14px !important;
  }
  .filter-heading {
    font-size: 21px;
    font-weight: 500;
    color: #4d4e8d;
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
  }
  .filter-clear-wrap {
    padding: 6px 20px 0 20px !important;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
  }
  .filter-clear-wrap p.clear {
    color: #c04646;
    font-size: 14px;
    cursor: pointer;
  }
  .filter-cards-title {
    font-size: 13px;
    color: #8ab6a1;
    margin-bottom: 5px;
  }
  .filter-list {
    padding: 6px;
  }
  .filter-list p {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    color: #000000;
    margin-bottom: 0;
  }